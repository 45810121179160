<template>
  <div class="white">
    <v-container fluid class="container">
      <v-layout fill-height>
        <v-row>
          <v-col cols="12" :md="wideBg ? '5' : '4'" class="backgroundCustom">
            <div class="px-12 logoBox">
              <div>
                <img src="../../assets/images/IDFL_Logo_White.png" alt="" />
              </div>
              <div class="white--text text--darken-5">
                {{ $t('signUpContent.signup_msg') }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" :md="wideBg ? '7' : '8'">
            <div class="pt-12 pb-4 px-8">
              <slot />
            </div>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'AuthBox',
  props: {
    wideBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>
<style lang="scss">
.container {
  height: 100vh;
}
.backgroundCustom {
  background-image: url('../../assets/images/background/authbackground.png');
  background-size: cover;
}
.logoBox {
  margin-top: 50%;
}
@media (max-width: 960px) {
  .logoBox {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
</style>
